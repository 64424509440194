.custom-dropdown {
    width: 164px;
    height: auto;
    border-radius: 6px;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 4px 0;
}

.custom-dropdown-menu {
    padding: 0;
    margin: 0;
    list-style: none;
}

.custom-dropdown-menu-item {
    padding: 8px 16px;
    cursor: pointer;
    display: block;
    width: 100%;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
    background: none;
    border: 0;
}

.custom-dropdown-menu-item:hover {
    background-color: #f5f5f5;
}

.custom-dropdown-menu-item:last-child {
    border-bottom: none;
}

.custom-dropdown-menu-item {
    display: flex;
    align-items: center;
}

.dropdown-item-icon {
    margin-right: 8px;
}
