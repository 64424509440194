.sidebar {
	background-color: #262626;
	height: 100vh;
	width: 216px;
	position: sticky;
	left: 0;
	top: 0;
	z-index: 100;
	overflow-y: auto;  
}

.sidebar::-webkit-scrollbar {
    display: none;
}

.sidebar > .ant-layout-sider-trigger {
	background-color: rgba(0, 0, 0, 0);
}

.sidebar > .ant-layout-sider-children {
	display: flex;
	flex-direction: column;
}

.sidebar-header {
	margin-top: 16px;
	display: flex;
    flex-direction: column;
	align-items: center;
}

.sidebar-menu {
	background-color: #262626;
	color: #fff;
	width: 100%;
	border-right: 0px;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
	width: 100%;
	font-size: 16px;
}

.sidebar-menu .ant-menu-item-selected,
.ant-menu-submenu-popup .ant-menu-item-selected {
	background-color: #595959 !important;
	color: #fff !important;
}

.sidebar-menu .ant-menu-item-selected::after {
	border-color: #e7202e;
}

.ant-menu-submenu .ant-menu-item {
	background-color: #262626;
	color: #fff !important;
	margin: 0;
	display: flex;
}

.ant-menu-submenu-selected {
	color: #e7202e;
}

.sidebar-menu
	> .ant-menu-submenu
	.ant-menu-submenu-title
	.ant-menu-submenu-arrow {
	color: inherit;
}

.sidebar-menu .ant-menu-item-active,
.sidebar-menu > .ant-menu-submenu-active .ant-menu-submenu-title,
.sidebar-menu
	> .ant-menu-submenu-active
	.ant-menu-submenu-title
	.ant-menu-submenu-arrow {
	color: #e7202e !important;
}

.ant-menu-sub.ant-menu-inline {
	background-color: transparent;
}

.sidebar.ant-layout-sider-collapsed .sidebar-header {
	width: 47px;
}

@media only screen and (max-width: 1200px) {
	.sidebar {
		position: fixed !important;
		z-index: 1000;
		
	}
	
	.sidebar.ant-layout-sider-collapsed {
		flex: 0 0 60px !important;
		max-width: 60px !important;
    	min-width: 60px !important;
		width: 60px !important;
	}

	.sidebar.ant-layout-sider-collapsed .ant-layout-sider-trigger {
		width: 60px !important;
	}

	.sidebar.ant-layout-sider-collapsed .sidebar-header {
		width: 32px;
	}
	.dogbyte-paw-logo {
		width: 32px !important;
	}
}

.pendo-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
	margin-bottom: 24px;
}

.new_patient_button,
.new_patient_button:hover
{
	background: var(--veg-secondary-lime);
	border: var(--veg-secondary-lime);
	color: #262626;
	margin-top: 18px;
}

.new_patient_button:hover{
	opacity: 0.8;
}

.hospital-name {
	color: var(--gray-6);
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 24px;
}

.sidebar-collapse-button {
	width: 100%;
    cursor: pointer;
	font-size: 24px;
	color: #fff;
	margin-top: 28px;
}

.sidebar-search-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 30px
}

.avatar-container {
	width: 100%;
	margin-bottom: 14px;
}

.avatar-container:hover {
	cursor: pointer;
}

.avatar-user-name { 
	margin-left: 10px;
	color: white;
	font-size: 16;
}
