.chat-wrapper {
	height: 100vh;
	position: fixed;
	right: -431px;
	top: 0;
	width: 430px;
	z-index: 1000;
	background-color: #fff;
	transition: all 0.3s;
	box-shadow: 0px 9px 28px 8px #0000000d;
}

.chat-wrapper--open {
	right: 0;
}

.chat-overlay {
	height: 100vh;
	left: 0;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 1000;
	background-color: #000;
	opacity: 0.45;
}

.chat-header {
	display: flex;
	justify-content: space-between;
	padding: 20px 24px;
	border-bottom: 1px solid var(--gray-4);
}

.chat-header h1 {
	color: var(--gray-9);
	font-weight: var(--font-semi-bold);
	font-size: 16px;
}

.chat-messages {
	height: calc(100vh - 197px);
    overflow: auto;
    padding: 12px 24px;
}

.chat-messages .ant-alert {
    margin-bottom: 16px;
    position: sticky;
    top: 0;
}

.chat-messages .chat-messages__message:not(:last-child) {
    margin-bottom: 16px;
}

.chat-text-input,
.chat-buttons {
	margin-top: auto;
	padding: 12px 24px;
}

.chat-text-input {
	border-top: 1px solid var(--gray-4);
}

.chat-text-input input {
    border-radius: 10px;
    padding: 9px 16px;
}

.chat-buttons {
	display: flex;
	justify-content: flex-end;
	gap: 8px;
}
