.send-record-to {
    margin-top: var(--spacing-lg);
}

.send-record-to .send-record-to__title {
    font-weight: var(--font-medium);
    margin-bottom: var(--spacing-sm);
}

.send-record-to .send-record-to__checkbox-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm) 0;
}

.send-record-to__checkbox-container .ant-form-item {
    padding: 0;
}

.send-record-to__checkbox-container .ant-form-item-control-input {
    min-height: auto;
}

.send-record-to .send-record-to__alert {
    margin-bottom: var(--spacing-sm);
}
