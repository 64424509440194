.idexx-reference-lab-modal .ant-alert {
    margin-bottom: var(--spacing-lg);
}

.idexx-reference-lab-modal .ant-input-affix-wrapper {
    margin-bottom: var(--spacing-xs);
}

.idexx-reference-lab-modal .idexx-reference-lab__details-link {
    display: block;
    margin-bottom: var(--spacing-sm);
}

.idexx-reference-lab-modal .idexx-reference-lab-item:not(:last-child) {
    border-bottom: 1px solid var(--gray-4);
}

.idexx-reference-lab-item {
    padding: var(--spacing-md) 0;
}

.idexx-reference-lab-item > *:not(:last-child) {
    margin-bottom: var(--spacing-sm);
}

.idexx-reference-lab-item__title {
    font-weight: var(--font-medium);
    font-size: 16px;
}

.idexx-reference-lab-item__bottom-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
