.print-estimate-page {
    width: 816px;
    margin: auto;
}

.print-estimate-page__print-button-container {
    display: flex;
    border-bottom: 1px solid var(--gray-5);
    padding: 10px 0;
    margin-bottom: 10px;
}

.print-estimate-page__print-button-container button {
    width: fit-content;
    margin-left: auto;
}

.print-estimate-page .document {
    margin: auto;
    height: calc(100dvh - 73px);
}

.print-estimate-page__approved-container {
    border: 1px solid var(--gray-5);
    padding: 24px;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--font-semi-bold);
}

@media screen and (max-width: 800px) and (min-width: 600px) {
    .print-estimate-page {
        width: 650px;
    }
}

@media screen and (max-width: 600px) {
    .print-estimate-page {
        width: 100%;
    }

    .print-estimate-page__print-button-container {
        padding: 10px;
    }
}
