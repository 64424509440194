.transfer-order-table .grid-table__header,
.transfer-order-table .grid-table__body > * {
    grid-template-columns: 350px 300px 400px 180px 100px 200px 200px 300px 64px;
}

.side-drawer.ant-drawer {
    height: 100vh;
    min-height: 0;
    max-width: none;
    max-height: none;
    transition: none;
    transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s;
}

.side-drawer .ant-drawer-body {
    transition: none;
    padding-top: 0;
}

.side-drawer.ant-drawer .ant-drawer-header {
    cursor: default;
}

.side-drawer.ant-drawer-right .ant-drawer-content-wrapper {
    height: 100%;
    max-height: none;
    overflow: auto;
}

.side-drawer .ant-drawer-header {
    display: flex;
    align-items: center;
}

.side-drawer .ant-drawer-close {
    position: relative;
    padding: 0;
    margin-left: auto;
}

.side-drawer .anticon-close {
    border: 1px solid;
    padding: 5px;
    font-size: 20px;
    border-color: var(--gray-5);
}

.side-drawer .checkout-item {
    padding: var(--spacing-md) 0;
    line-height: 20px;
}

.side-drawer .checkout-item button {
    display: flex;
    height: 20px;
    align-items: center;
    height: auto;
    margin: 0;
    padding: 0;
    min-height: 0;
}

.side-drawer .checkout-item svg {
    display: block;
}

.referral-source-modal .ant-form-item-label {
    display: block;
    width: 100%;
    text-align: left;
    white-space: initial;
}

.referral-source-modal .ant-form-item-label > label {
    height: auto;
    min-height: 32px;
}

.side-drawer.ant-drawer .ant-drawer-mask {
    animation: none !important;
}

.side-drawer .ant-drawer-footer {
    display: flex;
    gap: 8px;
}

.side-drawer .ant-drawer-footer button:first-child {
    margin-left: auto;
}

.side-drawer .ant-alert-info {
    align-items: flex-start;
    margin-top: 16px;
}

.side-drawer .ant-alert-info .anticon {
    margin-top: 0.2em;
}

.side-drawer .required-message {
    width: 100%;
    padding: var(--spacing-md) 0;
}

.side-drawer.referral-source-drawer .ant-space {
    padding: var(--spacing-md) 0;
}

.side-drawer.referral-source-drawer .primary-contact-message {
    padding-top: var(--spacing-md);
}

.side-drawer.referral-source-drawer .additional-info {
    white-space: pre-wrap;
}

.side-drawer.referral-source-drawer .additional-info-textarea {
    min-height: 8em;
}

.side-drawer.referral-source-drawer .referred-by-primary-vet {
    margin-top: 10px;
}

.side-drawer.referral-source-drawer .ant-radio-group {
    margin-top: 10px;
}

.serial-alert-column-transfer {
    position: sticky;
    left: 0;
    background-color: #fff;
    grid-row: 2;
    grid-column: 3/7;
    z-index: 1;
}

.serial-alert-column-transfer-medication {
    position: sticky;
    left: 0;
    background-color: #fff;
    grid-row: 3;
    grid-column: 3/7;
    z-index: 1;
}

.serial-alert-column-transfer--hide {
    display: none;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff !important;
    background: var(--antd-blue) !important;
    border-color: var(--antd-blue) !important;
}
