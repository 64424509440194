.payments-report-container {
    padding-left: 24px;
}

.payments-report-container h1 {
    font-size: 30px;
    margin-bottom: 24px;
    margin-top: 40px;
}

.payments-report-container > .sub-header {
    margin-bottom: 16px;
}
