.print-button {
	margin-right: 10px;
	border-radius: 5px;
}
.accept-button {
	border-radius: 5px;
}
.document-section-phone {
	display: none;
}
.document-section {
	margin: 80px 0px;
}
.document-text {
	font-size: 14px;
	font-family: AauxNext-Regular;
	font-weight: normal;

}
.document-text-bold {
	font-size: 14px;
	line-height: 12pt;
	font-weight: 550;
}

.bottom-section {
	display: flex;
	justify-content: space-between;
	max-width: 1000px;
	width: 816px;
	flex-wrap: wrap;
	padding: 40px;
	gap: 16px 0;
}

.single-page-element {
	page-break-inside: avoid;
}
.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}
.document {
	width: 816px;
	margin: 2%;
	margin-bottom: 0;
	border: 1px solid var(--gray-5);
	overflow-y: auto;
	background-color: #FFFFFF;
	padding: 40px;
	font-family: Roboto;
}

.mobile-only {
	display: none;
}

.medication-consent-form__signature-container {
	border-top: 1px solid var(--gray-8);
	width: 65%;
	display: none;
}

@media screen and (max-width: 800px) and (min-width: 600px) {
	.document {
		height: 850px;
		width: 650px;
	}
	.bottom-section {
		width: 650px;
	}
}

@media screen and (max-width: 600px) {
	.document-section-phone {
		display: block;
		width: 100%;
	}
	.document-section-desktop {
		display: none;
	}
	.desktop-only {
		display: none;
	}
	.document {
		height: 100%;
		width: 100%;
		padding: 16px;
		border: none;
		background-color: #FFFFFF;
	}
	.bottom-section {
		width: 100%;
		padding: 16px;
		border: none;
		border-top: 1px solid var(--gray-5);
	}
	.container {
		height: 100%;
	}
	.header-title {
		font-size: 16pt;
	}
	.mobile-only {
		display: block;
	}
	

}

@media print {
	* {
		visibility: hidden;
		/* background-color: #fff !important; */
	}

	html, body {
		height: 99%;    
	}
	
	.document {
		/* background-color: #fff !important; */
		border: 0;
		overflow-y: visible;
		padding: 0 48px;
		background-color: #FFFFFF;
		margin: 0;

	}
	.container {
		margin: 0;
		padding: 0;
	}
	#printableArea * {
		visibility: visible;
	}
	.new-page {
		page-break-after: always;
	}

	.medication-consent-form__signature-container {
		display: block;
	}

	@page {
		size: Letter;
	}
}

.header-info-text{
	font-family: Roboto;
	margin-bottom: 12px;
	font-weight: 400;
	font-size: 12px;
	line-height: 10px;
}

.rabies-vaccination-container .header-info-text .ant-input {
	width: 90% !important;
}

.header-doctor-name{
	font-size: 14px;
	line-height: 20px;
	text-transform: capitalize;
}

/* Colors */

.veg-red {
	color: #ed1c24;
}

.veg-grey {
	color: #8C8C8C
}
/* Utilities */

.text-right {
	text-align: right;
}

.wt600 {
	font-weight: 600;
}

.print-message-checked,
.print-message-unchecked
{
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 22px;
	color: #262626;
}

.print-message-unchecked {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 40px;
	border: 1px solid #D9D9D9;
	border-radius: 2px;
}



