.level-popover {
    width: 520px;
}

.level-popover .ant-popover-title {
    max-height: 56px;
    font-size: 16px;
    font-weight: var(--font-semi-bold);
    padding: var(--spacing-md) var(--spacing-lg);
    padding-right: var(--spacing-sm);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.level-popover .ant-popover-title > button {
    border: none;
    color: var(--gray-7);
    box-shadow: none;
}

.level-popover .ant-popover-title > button > span {
    font-size: 10px;
}

.level-popover .ant-popover-inner-content {
    padding: 0;
}

.level-popover .triage-level__content {
    padding: var(--spacing-lg);
}

.triage-level__content .ant-radio-button-wrapper-checked.triage-level__btn-critical {
    color: white !important;
    background-color: var(--veg-red);
}

.triage-level__content .ant-radio-button-wrapper-checked.triage-level__btn-urgent {
    background-color: var(--veg-tint-gold);
}

.triage-level__content .ant-radio-button-wrapper-checked.triage-level__btn-stable {
    color: white !important;
    background-color: var(--gray-8);
}

.level-popover .level-popover__footer {
    padding: var(--spacing-sm) var(--spacing-md);
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-sm);
    border-top: 1px solid var(--gray-4);
}

.level-popover button.level-popover__save:not([disabled]) {
    color: white;
    background-color: var(--veg-secondary-teal);
}
