.discharge-section {
    background: #fff;
}

.anticon.plus-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: var(--gray-9);
    border: 1px solid var(--gray-5);
    width: 24px;
    height: 24px;
    border-radius: 2px;
}

.discharge-instructions-table td.centered-column,
.discharge-instructions-table .ant-table-thead > tr > th.centered-column {
    text-align: center;
}

.discharge-instructions-table .dose-icon-morning,
.discharge-instructions-table .dose-icon-afternoon {
    width: 20px;
}

.discharge-instructions-table .dose-icon-night {
    width: 14px;
}

.multi-line-message .title {
    font-weight: var(--font-medium);
}

.multi-line-message .ant-message-notice-content {
    text-align: left;
}

.multi-line-message .ant-message-custom-content {
    display: flex;
}

.ant-message .multi-line-message .anticon {
    top: 3px;
}

.discharge-note__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--gray-7);
}

.discharge-note__footer .ant-avatar {
    margin-right: var(--spacing-sm);
    background-color: var(--veg-tint-lime);
}

.discharge-note__footer .discharge-note__button-container {
    margin-left: auto;
}
