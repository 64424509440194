.macro-card {
    height: 100%;
}

.macro-card div.veg-subcaption-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.macro-card:hover {
    cursor: pointer;
}
