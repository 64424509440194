.chat-message-header {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.chat-message-header h2 {
	font-size: 14px;
	font-weight: var(--font-semi-bold);
	margin: 0;
	color: var(--gray-8);
}

.chat-message__phone-number {
	color: var(--gray-8);
}

.chat-message__date {
	color: var(--gray-7);
	font-size: 12px;
}
