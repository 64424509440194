.__fade-transition-mixin {
	transition: all linear 250ms;
}
.dashboardHeader {
	background-color: #fff;
	padding: 1.5rem 2rem;
	align-items: center;
	border-bottom: 1px solid #e9ebef;
}
.dashboardHeader :global(h2) {
	margin-bottom: 0 !important;
}
.tableWrapper {
}
.tableWrapper :global .ant-table-title {
	padding: 10px 16px;
	background-color: #fff;
}
.tableWrapper :global .ant-table-title h3 {
	margin: 0;
}
.actionable {
	background-color: chartreuse;
}
.switch:global(.ant-switch) {
	margin-right: 1rem;
}
.switchLabel {
	margin-right: 10px;
}
.searchInput {
	width: 300px;
	margin-left: 1rem;
}
.row {
	cursor: pointer;
}
