.diagnostic-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.diagnostic-data__last-value {
    color: var(--gray-8);
}

.diagnostic-data .order-frequency-wrapper {
    margin-left: auto;
    border-radius: 2px;
}

.treatment-sheet-side-column__instruction-icon + .order-frequency-wrapper {
    margin-left: 0;
}
