.pageHeader {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 1.5rem 1rem;
  align-items: center;
  border-bottom: 1px solid #e9ebef;
}
.pageHeader :global(h2) {
  margin-bottom: 0 !important;
}
