.treatment-sheet-side-column__title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ant-tag-processing.no-charge-tag {
    margin: 0;
    margin-bottom: var(--spacing-xs);
}
