.medication-dose-alert-section {
    margin-top: var(--spacing-md);
    width: 100%;
    min-height: 150px;
}

.medication-dose-alert-section .ant-alert {
    padding: var(--spacing-sm) var(--spacing-md);
}

.medication-dose-alert-section .anticon svg {
    font-size: 16px;
}

.medication-dose-alert-section .ant-alert > .anticon svg {
    margin-top: 5px;
}

.medication-dose-alert-section .ant-alert-info {
    background-color: var(--gray-5);
    border-color: var(--gray-9);
}

.medication-dose-alert-section .ant-alert-info .anticon-question svg {
    fill: var(--gray-9);
}

.medication-dose-alert-section .ant-alert-error .anticon-alert svg {
    fill: var(--veg-secondary-red);
}

.medication-dose-alert-section .ant-alert-message {
    font-weight: var(--font-medium);
    display: flex !important;
    align-items: center;
}

.medication-dose-alert-section .ant-alert-message .anticon {
    margin-left: auto;
}

.medication-dose-alert-section .ant-alert-message,
.medication-dose-alert-section .ant-alert-description {
    color: var(--gray-9);
}

.medication-dose-alert-section .ant-checkbox-wrapper {
    margin-top: var(--spacing-sm);
}

.medication-dose-alert--horizontal {
    line-height: normal;
}

.medication-dose-alert--horizontal :first-child {
    text-transform: uppercase;
}

.medication-dose-alert--horizontal :last-child {
    font-weight: var(--font-regular);
}
