.note-editor textarea {
	width: 100%;
	padding: 10px;
}
.macro-popover .ant-popover-inner-content {
	padding: 0px;
}
.macro-popover .ant-popover-inner-content ul {
	width: 280px;
	list-style: none;
	padding: 0px 0px 2px 0px;
}
.macro-popover .ant-popover-inner-content ul li.no-items {
	text-align: center;
	color: #b7b7b7;
	border: 1px solid #e2e2e2;
	padding: 12px 0;
	border-radius: 2px;
	margin: 10px;
}
.macro-popover .ant-popover-inner-content ul li.macro {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 9px 14px;
	cursor: pointer;
}
.macro-popover .ant-popover-inner-content ul li.macro.selected,
.macro-popover .ant-popover-inner-content ul li.macro:hover {
	background-color: #eff6ff;
}
