.observation_container {
	border-radius: 8px;
	padding: 6px 12px;
	margin-bottom: 12px;
}

.phys-exam-data-section {
	text-transform: capitalize;
}

.observation_title {
	color: white;
	padding: 2px 6px;
	border-radius: 8px;
	width: fit-content;
}

.exam_observation_key {
	font-size: 14px;
	font-weight: bold;
}

.observation_container.not_observed {
	background-color: #f7f7f7;
}

.observation_container.normal {
	background-color: #73c64c33;
}

.observation_container.abnormal {
	background-color: rgba(255, 131, 100, 0.2);
}

.observation_container.abnormal .observation_title {
	background-color: #e15555;
}

.observation_container.not_observed .observation_title {
	background-color: #969696;
}

.observation_container.normal .observation_title {
	background-color: #73c64c;
}

.exam-note {
	margin-left: 12px;
}
