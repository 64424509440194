.spike-3-popover {
    width: 430px;
}

.spike-3-popover p {
    margin-bottom: 0;
}

.spike-3-popover .ant-popover-title {
    font-size: 16px;
    font-weight: var(--font-semi-bold);
    padding: var(--spacing-md) var(--spacing-lg);
    padding-right: var(--spacing-sm);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.spike-3-popover .ant-popover-title > button {
    border: none;
    color: var(--gray-7);
    box-shadow: none;
}

.spike-3-popover .ant-popover-inner-content {
    padding: 0;
}

.spike-3-popover .spike-3-popover__content {
    padding: var(--spacing-lg);
}

.spike-3-popover .spike-3-popover__instruction {
    font-weight: var(--font-semi-bold);
    margin-bottom: var(--spacing-lg);
}

.spike-3-popover .spike-3__doctor {
    padding-top: var(--spacing-lg);
    margin-top: var(--spacing-lg);
    border-top: 1px solid var(--gray-4);
}

.spike-3-popover__footer {
    padding: var(--spacing-sm) var(--spacing-md);
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-sm);
    border-top: 1px solid var(--gray-4);
}

.spike-3-popover .spike-3-popover__unmark-button,
.spike-3-popover .spike-3-popover__unmark-button:active,
.spike-3-popover .spike-3-popover__unmark-button:hover {
    background-color: var(--red-5);
    color: #fff;
    border-color: transparent;
}
