.status-item {
    color: var(--gray-9);
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid var(--gray-4);
    border-radius: 2px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    min-height: 115px;
}

.status-item__ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.status-item:not(:last-child) {
    margin-bottom: var(--spacing-sm);
}

.status-item > * {
    margin-left: var(--spacing-sm);
    padding: var(--spacing-xs) var(--spacing-sm);
}

.status-item::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 0.5rem;
}

/* DEFAULT - used when no triage level is available */
.status-item--default::before {
    background-color: var(--gray-2);
}

/* STABLE */
.status-item--stable::before {
    background-color: var(--gray-8);
}

/* URGENT */
.status-item--urgent::before {
    background-color: var(--veg-secondary-gold);
}

/* CRITICAL */
.status-item--critical::before {
    background-color: var(--veg-secondary-red);
}

.status-item__patient-infos > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.status-item__patient-name {
    color: #000;
    font-size: 1.25rem;
    font-weight: var(--font-medium);
    line-height: 24px;
    max-width: 60%;
}

.status-item__visit-infos {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
}

.status-item__arrival-time,
.status-item__location-in-hospital {
    color: var(--gray-8);
    margin-left: auto;
    white-space: nowrap;
}

.status-item__location-in-hospital .anticon {
    margin-right: var(--spacing-xs);
}

.status-item__reason {
    max-width: 100%;
}

.status-item-instructions {
    background-color: var(--gray-4);
    color: var(--gray-9);
    font-size: 1rem;
    line-height: normal;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-top: auto;
}

.status-item-instructions__type {
    font-size: 0.75rem;
}
