.estimates-table-test > * .ant-table-thead > tr > th {
	background-color: #e5e5e5 !important;
}

.estimates-table-override > * .ant-table-title {
	background-color: #F0F5F7 !important;
}
.estimates-table-override > * .ant-table-thead > tr > th {
	background-color: #FAFAFA !important;
  white-space: nowrap;
}
