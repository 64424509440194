.serial-alert-section {
    width: 100%;
}

.serial-alert-section .ant-alert {
    padding: var(--spacing-sm);
}

.serial-alert-section .anticon svg {
    font-size: 16px;
}


.serial-alert-section .ant-alert-info {
    background-color: var(--gray-5);
    border-color: var(--gray-9);
}

.serial-alert-section .ant-alert-info .anticon-question svg {
    fill: var(--gray-9);
}


.serial-alert-section .ant-alert-message {
    font-weight: var(--font-medium);
    display: flex !important;
    align-items: center;
}

.serial-alert-section .ant-alert-message .anticon {
    margin-left: auto;
}

.serial-alert-section .ant-alert-message,
.serial-alert-section .ant-alert-description {
    color: var(--gray-9);
}

.serial-alert-section-in-form .ant-checkbox-wrapper {
    margin-top: var(--spacing-sm);
}

.serial-alert-section-in-form {
    margin-top: var(--spacing-md);
    margin-bottom: var(--spacing-md);
}

.serial-alert-section-in-form .ant-alert > .anticon svg {
    margin-top: 5px;
}

.serial-alert-message :first-child {
    text-transform: uppercase;
}

.serial-alert-message :last-child {
    font-weight: var(--font-regular);
    text-transform: none;
}


