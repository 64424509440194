.reschedule-action-form .reschedule-action-form__scheduled-for {
    font-weight: var(--font-medium);
}

.reschedule-action-form .reschedule-action-form__alert {
    margin-top: var(--spacing-lg);
}

.reschedule-action-form__new-time-funcs {
    display: inline-flex;
    margin-left: var(--spacing-sm);
    border: 1px solid var(--gray-5);
    border-radius: 2px;
}

.reschedule-action-form__new-time-funcs .ant-btn {
    border: none;
    max-width: 48px;
    height: 30px;
    padding: 0 var(--spacing-sm);
}

.reschedule-action-form__new-time-funcs .ant-btn:not(:last-child) {
    border-right: 1px solid var(--gray-5);
}
