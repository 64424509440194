.rc-virtual-list-holder-inner .ant-select-item-option-active:not(.ant-select-item-option-disabled):has(.search-result) {
	background-color: white; 
	cursor: default;
}

.rc-virtual-list-holder-inner .ant-select-item-option:has(.search-result) {
	padding: 0px;
}

.ant-select-item-empty:has(.search-result) {
	padding: 0px;
}

.search-result {
	padding-right: 24px;
	padding-left: 24px;
}

.patient-search-1 {
	width: 100%;
	justify-content: center;
}

.patient-search-1 .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector  {
	background-color: transparent !important;
	border: 1px solid #595959;
}

.patient-search-1 .ant-select-auto-complete .ant-select-clear {
    background: transparent;
    color: #8C8C8C;
}

.patient-search-1 
.ant-select:not(.ant-select-customize-input)
.ant-select-selector
.ant-select-selection-search-input {
	color: #8C8C8C;
}