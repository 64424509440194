.macro-content .ant-form-item-control-input,
.macro-content .ant-form-item-row,
.macro-content .ant-form-item-control-input-content {
    height: 100%;
}

.macro-details-required::after {
    content: ' * ';
    color: var(--red-6);
}

#macro-details-form .ant-input-disabled,
#macro-details-form .ant-input-affix-wrapper-disabled,
#macro-details-form .ant-select-disabled .ant-select-selector,
#macro-details-form .ant-select-disabled .ant-select-arrow {
    cursor: default;
    color: rgba(0, 0, 0, 0.75);
}
