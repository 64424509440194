.whiteboard-doctor-nurse-tag {
    color: #fff;
    line-height: normal;
    font-weight: var(--font-semi-bold);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    text-transform: uppercase;
}

.whiteboard-doctor-tag {
    border-radius: 50%;
}
