.note-section {
    width: 100%;
    margin-top: 10px;
}

.add-note-button {
    all: inherit !important;
    cursor: pointer !important;
    color: var(--antd-blue) !important;
    font-weight: var(--font-medium) !important;
}
