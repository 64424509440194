.phone-number-field-component {
    width: 100% !important;
}
.phone-number-field-component  > .ant-input-number-handler-wrap {
    display: none !important;
}

.phone-number-field-component-form-item .ant-form-item-extra {
    position: absolute;
    left: 0.9em;
    top: 0.4em;
    font-size: 14px;
    color: inherit;
}

.phone-number-field-component .ant-input-number-input {
    padding-left: 2.2em;
}
