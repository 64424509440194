.minimized-estimate-table .ant-table-container {
    display: none;
}

.estimate-table-list:not(:first-child) {
    margin-top: 24px;
}

.estimate-table-list  .ant-table-title {
    background: #F0F5F7 !important;
}


.estimate-section {
    background: #FFF;
}
.estimate-table-list .ant-table-thead .ant-table-cell {
    font-weight: bold;
}

.estimate-modal-layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

}

.estimate-modal-ledger-container {
    justify-content: space-between !important;
}

.estimate-modal-ledger-container .ant-alert {
    margin-top: 20px;
    height: fit-content;
}

.estimate-modal-order{
    padding-top: 12px;
}

.estimate-duration-form-item .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    border: 1px solid var(--gray-5);
    border-radius: 2px;
}

.estimate-duration-form-item .ant-form-item-control-input-content > span {
    display: flex;
    align-items: center;
    padding: 0 var(--spacing-sm);
    background-color: var(--gray-2);
    align-self: stretch;
}

.estimate-duration-select .ant-select-selector {
    text-transform: lowercase;
}

.ant-space-compact-block.estimate-modal-save-dropdown {
    padding-left: 8px;
    width: auto;
}
