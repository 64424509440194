.package-modal__title {
    display: flex;
    gap: 0 var(--spacing-md);
    align-items: center;
    font-size: var(--h1-size);
    padding: var(--spacing-lg) 0;
    margin-top: calc(var(--spacing-lg) * -1);
    margin-bottom: 0;
    position: sticky;
    top: calc(var(--spacing-lg) * -1);
    z-index: 3;
    background-color: #fff;
}

.package-modal__title > span {
    padding-right: var(--spacing-md);
    border-right: 1px solid var(--gray-7);
}

.package-modal__title .ant-form-item-control {
    flex-direction: row;
    font-size: var(--h1-size);
    align-items: center;
}

.package-modal__title .ant-form-item-control .ant-form-item-explain {
    margin-left: var(--spacing-sm);
    min-height: initial;
    font-weight: normal;
}

.package-modal__title .package-modal__patient-weight-input {
    width: 110px;
}

.package-modal__title .ant-form-item,
.grid-table__body .ant-form-item {
    padding-top: 0;
}

.package-modal__error-message {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: var(--spacing-sm) 0;
    color: var(--gray-9);
}

.package-modal__error-message .anticon {
    margin-left: var(--spacing-sm);
}

.package-modal .ant-form-item-control-input {
    min-height: 0;
}

.grid-table__header,
.instruction-type-section > span {
    color: var(--gray-9);
    font-weight: var(--font-semi-bold);
}

.grid-table__header {
    background-color: var(--gray-2);
    align-items: center;
    position: sticky;
    top: calc(var(--spacing-lg) * 2 + 10px);
    z-index: 3;
}

.grid-table__header,
.grid-table__body > * {
    display: grid;
    grid-template-columns: 48px 350px 420px repeat(2, 100px) repeat(3, 200px) 64px;
}

.grid-table__header > *,
.grid-table__row > * {
    padding: var(--spacing-sm) !important;
}

.grid-table__row.grid-table__row-non-med {
    grid-template-columns: 48px 350px 130px 440px 50px repeat(3, 200px) 64px;
}

.grid-table__row.grid-table__row-non-med .ant-form-item {
    padding-bottom: 8px;
}

.grid-table__full-width-row {
    grid-template-columns: 1fr !important;
    position: sticky;
    left: 0;
    background-color: var(--veg-light-blue);
    padding: var(--spacing-sm);
}

.grid-table__header,
.grid-table__body {
    max-width: 100%;
    overflow: auto;
}

.grid-table__header::-webkit-scrollbar,
.grid-table__body::-webkit-scrollbar {
    display: none;
}

.grid-table__sticky-column {
    position: sticky;
    background-color: inherit;
    z-index: 2;
}

.grid-table__header .grid-table__sticky-column:nth-child(1),
.grid-table__row .grid-table__sticky-column:nth-child(2) {
    left: 0;
}

.grid-table__row .grid-table__sticky-column:nth-child(3),
.grid-table__header .grid-table__sticky-column:nth-child(2) {
    left: calc(var(--spacing-sm) * 6);
}

.grid-table__row .grid-table__sticky-column:nth-child(3) {
    padding-right: var(--spacing-md) !important;
}

.grid-table__header .grid-table__sticky-column:nth-child(2)::after,
.grid-table__row .grid-table__sticky-column:nth-child(3)::after,
.grid-table__header .grid-table__sticky-column:nth-child(1)::after,
.grid-table__row .grid-table__sticky-column:nth-child(2)::after,
.grid-table__header .grid-table__sticky-column:last-child::after,
.grid-table__row .grid-table__sticky-column:last-child::after {
    position: absolute;
    top: 0;
    bottom: -1px;
    width: 30px;
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
}

.grid-table__header .grid-table__sticky-column:nth-child(2)::after,
.grid-table__row .grid-table__sticky-column:nth-child(3)::after,
.grid-table__header .grid-table__sticky-column:nth-child(1)::after,
.grid-table__row .grid-table__sticky-column:nth-child(2)::after {
    right: 0;
    transform: translate(100%);
}

.show-left-shadow .grid-table__sticky-column:nth-child(2)::after,
.show-left-shadow .grid-table__row .grid-table__sticky-column:nth-child(3)::after {
    box-shadow: inset 10px 0 8px -8px #00000026;
}

.show-left-estimate-shadow .grid-table__sticky-column:nth-child(1)::after,
.show-left-estimate-shadow .grid-table__row .grid-table__sticky-column:nth-child(2)::after {
    box-shadow: inset 10px 0 8px -8px #00000026;
}

.grid-table__header .grid-table__sticky-column:last-child,
.grid-table__row .grid-table__sticky-column:last-child {
    right: 0;
    align-items: center;
}

.grid-table__header .grid-table__sticky-column:last-child::after,
.grid-table__row .grid-table__sticky-column:last-child::after {
    left: 0;
    transform: translate(-100%);
}

.show-right-shadow .grid-table__header .grid-table__sticky-column:last-child::after,
.show-right-shadow .grid-table__row .grid-table__sticky-column:last-child::after {
    box-shadow: inset -10px 0 8px -8px #00000026;
}

.show-right-estimate-shadow .grid-table__header .grid-table__sticky-column:last-child::after,
.show-right-estimate-shadow .grid-table__row .grid-table__sticky-column:last-child::after {
    box-shadow: inset -10px 0 8px -8px #00000026;
}

.grid-table__body > .grid-table__row {
    border-bottom: 1px solid var(--gray-4);
}

.grid-table__row {
    background-color: #fff;
}

.instruction-type-section {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.instruction-type-section .anticon {
    margin-right: var(--spacing-lg);
}

.hidden-section {
    display: none;
}

.fluid-dose-container {
    display: inline-flex;
    gap: 0 var(--spacing-sm);
}

.disabled-line {
    background-color: var(--gray-3);
    opacity: 0.6;
}

.disabled-line__form-inputs {
    background-color: var(--gray-3);
    grid-column: 3/10;
}

.package-modal__mobile-header {
    display: none;
}

.non-medical-quantity {
    width: 130px;
}

/* Estimate styles */

.estimate-table .grid-table__header,
.estimate-table .grid-table__body > * {
    grid-template-columns: 48px 350px 420px repeat(2, 120px) 1fr;
}

@media only screen and (max-width: 600px) {
    .package-modal {
        width: 100vw !important;
        height: 100vh !important;
        top: 0 !important;
    }

    .package-modal .ant-modal-body {
        max-height: calc(100vh - (var(--spacing-md) * 4)) !important;
    }

    .package-modal .ant-picker {
        width: 100%;
    }

    .empty-element {
        display: none;
    }

    .package-modal__title {
        display: block;
    }

    .package-modal__title > span {
        border: none;
    }

    .package-modal__mobile-header {
        display: block;
        font-weight: var(--font-medium);
    }

    .grid-table__header,
    .grid-table__body > *:not(.hidden-section) {
        display: flex;
        flex-direction: column;
    }

    .grid-table__header :first-child {
        width: 100%;
    }

    .grid-table__header .grid-table__sticky-column:nth-child(2),
    .grid-table__header :nth-child(n + 3) {
        display: none;
    }

    .edit-button-container.grid-table__sticky-column {
        position: absolute;
        right: var(--spacing-sm) !important;
    }

    .grid-table__sticky-column {
        position: static;
    }

    .grid-table__header,
    .grid-table__body {
        overflow: hidden;
    }

    .grid-table__row {
        position: relative;
    }

    .show-right-shadow .grid-table__header .grid-table__sticky-column:last-child::after,
    .show-right-shadow .grid-table__row .grid-table__sticky-column:last-child::after,
    .show-left-shadow .grid-table__sticky-column:nth-child(2)::after,
    .show-left-shadow .grid-table__row .grid-table__sticky-column:nth-child(3)::after {
        box-shadow: none;
    }

    .show-right-estimate-shadow .grid-table__header .grid-table__sticky-column:last-child::after,
    .show-right-estimate-shadow .grid-table__row .grid-table__sticky-column:last-child::after,
    .show-left-estimate-shadow .grid-table__sticky-column:nth-child(1)::after,
    .show-left-estimate-shadow .grid-table__row .grid-table__sticky-column:nth-child(2)::after {
        box-shadow: none;
    }

    .non-medical-quantity {
        width: 100%;
    }

    .non-medical-reason-info .ant-col {
        flex: 0 0 100%;
        margin-left: 0;
    }
}

.serial-alert-column {
    position: sticky;
    left: 0;
    background-color: #fff;
    grid-column: 3/7;
    grid-row: 2;
    z-index: 1;
}

.serial-alert-column-medication {
    position: sticky;
    left: 0;
    background-color: #fff;
    grid-column: 3/7;
    grid-row: 3;
    z-index: 1;
}

.serial-alert-column--hide {
    display: none;
}
