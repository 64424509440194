.reconfirmModal .ant-modal-footer {
    display: flex;
    justify-content: center;
    padding: 10px 24px
}

.reconfirmModal .ant-btn {
    flex: 1;
    display: flex; 
    align-items: center;
    justify-content: center;
}
