.sidebarSection {
  padding-bottom: 24px;
}

.sidebarSectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  font-weight: var(--font-semi-bold);
  margin: 0;
  text-transform: uppercase;
}
.sidebarSectionTitle {
  font-weight: var(--font-semi-bold);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 22px;
}

.sidebar-instructional-text {
  color: var(--gray-8);
}
.clickable-text {
  padding-bottom: 12px;
}
.badge :global(sup) {
  background-color: inherit;
  font-size: 14px;
  font-weight: bold;
}
.chronicBadge :global(sup) {
  color: #d32027;
  border-color: #d32027;
}
.problemBadge :global(sup) {
  color: #d37420;
  border-color: #d37420;
}

.patientIdRow {
  border-top: 1px solid #d9d9d9;
  padding: 10px;
}
