.__fade-transition-mixin {
  transition: all linear 250ms;
}
.pageWrapper {
  display: flex;
  height: calc(100vh - 56px);
}
.visitPreview :global .ant-divider-horizontal {
  margin: 12px 0;
}
.visitPreview :global .ant-card-head-title {
  white-space: break-spaces;
}
.patientSidebar {
  border-right: 1px solid #d9d9d9;
  background-color: #fbfcfd;
  width: 25%;
  min-height: calc(100vh - 56px);
  padding: 24px;

}
.patientMainContent {
  width: 75%;
}
.sidebarPetName {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 24px;
}
.editSidebarIcon:hover {
  opacity: 0.6;
  cursor: pointer;
}
.patientTabs :global .ant-tabs-nav {
  background-color: #fbfcfd;
  padding-right: 12px;
  margin: 0;
}
.patientTabs :global .ant-tabs-nav .ant-tabs-tab {
  padding: 12px 15px !important;
}
.tabItem {
  padding: 12px;
}

.patientIdRow {
  border-top: 1px solid #d9d9d9;
  padding: 10px;
}
