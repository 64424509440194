.veg-drawer.ant-drawer {
    height: 100vh;
    min-height: 0;
    max-width: none;
    max-height: none;
    transition: none;
    transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s;
}

.veg-drawer .ant-drawer-header {
    display: flex;
    align-items: center;
}

.veg-drawer .ant-drawer-header .ant-drawer-close {
    position: absolute;
    right: 0;
    padding: 0;
    margin-right: 24px;
}

.veg-drawer .ant-drawer-header .anticon-close {
    border: 1px solid;
    padding: 5px;
    font-size: 20px;
    border-color: var(--gray-5);
}

.veg-drawer.ant-drawer-right .ant-drawer-content-wrapper {
    height: 100%;
    max-height: none;
    overflow: auto;
}

.veg-drawer .ant-drawer-body {
    transition: none;
}

.veg-drawer.ant-drawer .ant-drawer-header {
    cursor: default;
}

.veg-drawer .ant-drawer-footer {
    display: flex;
    gap: 8px;
}

.veg-drawer .ant-drawer-footer button:first-child {
    margin-left: auto;
}

.veg-drawer .ant-alert-info {
    align-items: flex-start;
    margin-top: 16px;
}

.veg-drawer .ant-alert-info .anticon {
    margin-top: 0.2em;
}

.veg-drawer-collapse .ant-collapse-item .ant-collapse-content-box {
    padding: 0;
}

.ant-collapse > .veg-drawer-panel.ant-collapse-item > .ant-collapse-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 12px 0;
    margin: 0;
}

.ant-collapse > .veg-drawer-panel.ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: 0;
    top: 8px;
}

.ant-collapse > .veg-drawer-panel.ant-collapse-item > .ant-collapse-header .ant-divider-horizontal.ant-divider-with-text-left::before {
    width: 1rem;
}

.veg-drawer-panel .ant-collapse-header .ant-divider-horizontal {
    margin: 0;
    width: 100%;
    clear: initial;
    min-width: 0;
    padding-right: 8px;
}

.veg-drawer-panel .ant-divider-inner-text {
    padding-left: 0;
}

.veg-drawer-panel .ant-divider-with-text-left::before {
    display: none;
}
