.estimates-table {
	width: 100%;
}
.estimate-row-header {
	margin: 20px 16px 10px 0;

}

.estimate-row {
	margin-bottom: 20px;
}


.ledger-box {
	border-width: 1.5px 1.5px 0 1.5px;
	border-style: solid;
	border-color: #D9D9D9;
	padding: 4px;
}
.ledger-box-bottom {
	border: 1.5px solid #D9D9D9;
	padding: 4px;
}
.ledger {
	text-align: right;
	display: flex;
	width: 40%;
	margin-top: 20px;
}



@media (max-width: 500px) {
	.estimate-user-desktop {
		display: none;
	}
	.estimate-calc-box {
		border: 0.25px solid grey;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 5%;
		width: 100%;
	}
	.ledger {
		width: 100%;
	}
}
@media (min-width: 500px) {
	.estimate-user-mobile {
		display: none;
	}
	.estimate-calc-box {
		border: 0.5px solid black;
		border-radius: 5%;
		display: flex;
		flex-direction: column;
		align-items: flex-star;
		margin: 10%;
		padding: 5%;
		width: 400px;
		height: 200px;
	}
}

@media print {
	.estimate-user-mobile {
		display: none;
	}
	.estimate-calc-box {
		border: 0.5px solid black;
		border-radius: 5%;
		display: flex;
		flex-direction: column;
		align-items: flex-star;
		margin: 10%;
		padding: 5%;
		width: 400px;
		height: 200px;
	}

}

.grey-5-force {
	background-color: #e5e5e5 !important;
}
