.contacts-page {
    padding: var(--spacing-xl) var(--spacing-lg);
}

.contacts-page h1 {
    font-size: var(--spacing-lg);
    margin-bottom: var(--spacing-lg);
}

.contacts-page .ant-input-affix-wrapper {
    max-width: 240px;
    margin-bottom: var(--spacing-md);
}
