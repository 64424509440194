.customer-sidebar {
    position: fixed;
    height: calc(100vh - 56px);
    width: 275px;
    background-color: var(--gray-2);
    padding: var(--spacing-xl) var(--spacing-lg);
}

.customer-sidebar .customer-sidebar__customer-name {
    font-size: 30px;
    color: #000;
    font-weight: var(--font-medium);
}

.customer-sidebar .customer-sidebar__customer-name > div {
    display: flex;
    align-items: center;
    line-height: normal;
}

.customer-sidebar__edit-button {
    border: none;
    background: transparent;
    margin-left: var(--spacing-sm);
}

.customer-sidebar__edit-button:hover {
    background: inherit;
}

.customer-sidebar__customer-info,
.customer-sidebar__customer-address,
.customer-sidebar__customer-birthday,
.customer-sidebar__customer-note {
    margin-top: var(--spacing-lg);
    color: var(--gray-8);
}

.customer-sidebar__section-title {
    display: inline-block;
    text-transform: uppercase;
    font-weight: var(--font-semi-bold);
    margin-bottom: var(--spacing-md);
}

@media only screen and (max-width: 600px) {
    .customer-sidebar {
        position: relative;
        width: 100vw;
        height: fit-content;
    }
}
