.whiteboard__indicator {
    background-color: var(--gray-3);
    text-align: center;
    text-transform: uppercase;
    padding: var(--spacing-xs) var(--spacing-sm);
    min-width: 8.25rem;
    border-radius: 4px;
}

.whiteboard__indicator-info {
    font-size: 2.5rem;
    color: var(--gray-9);
}

.whiteboard__indicator-description {
    font-size: 0.625rem;
    font-weight: var(--font-medium);
    color: var(--gray-8);
}

@media only screen and (max-width: 1320px) {
    .whiteboard__indicator {
        min-width: unset;
        width: 25%;
    }

    .whiteboard__indicator-info {
        font-size: 1.5rem;
    }

    .whiteboard__indicator-description {
        font-size: 0.5rem;
    }
}
