.medication-data {
	display: flex;
	justify-content: space-between;
    align-items: center;
}

.medication-data__dose-concentration {
    display: flex;
    gap: 0 20px;
    color: var(--gray-8);
}

.medication-data__route-freq {
    display: flex;
    border-radius: 2px;
    overflow: hidden;
}

.medication-data__route-id {
    display: flex;
    align-items: center;
    padding: 0 5px;
    background-color: var(--gray-7);
    color: #fff;
    font-weight: var(--font-medium);
}

.medication-data__dose-administered {
    display: flex;
    align-items: center;
    padding: 0 5px;
    border: 1px solid  var(--gray-7);
}

