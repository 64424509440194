.resultWrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.successTitle{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: var(--gray-9);
}

.resultSubTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--gray-7);
}

.vegMarquee {
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  bottom: 20%;
  margin: auto;
}
