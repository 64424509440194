.admit-patient-modal .ant-modal-body {
    max-height: 60vh;
    overflow: auto;
}

.admit-patient-hospitals {
    margin-top: 16px;
}

.admit-patient-hospital {
    margin: 12px 0;
    /* background: #def; */
}

.admit-patient-hospital:last-child {
    margin: 0;
}

.admit-patient-action-button-col {
    margin-left: auto;
    display: flex; 
    align-items: center;
}

.admit-patient-action-button-col button {
    border: 0;
    margin: 0;
    padding: 0;
    height: auto;
    display: inline-flex;
}

.admit-patient-hospital-name {
    display: flex;
    align-items: center;
    color: var(--veg-secondary-lime);
    font-size: 12px;
    font-weight: var(--font-semi-bold);
    text-transform: uppercase;
}

.admit-patient-customer .ant-row {
    margin: 8px 0;
}

.admit-patient-customer-name {
    display: flex;
    align-items: center;
    font-weight: var(--font-semi-bold);
}

.admit-patient-customer-divider {
    margin: 12px 0;
}

.admit-patient-customer:last-child .admit-patient-customer-divider {
    display: none;
}

.alert-with-icon-top.ant-alert {
    align-items: flex-start;
    margin-top: 16px;
}

.alert-with-icon-top.ant-alert .anticon {
    margin-top: 0.25em;
}
