.completed-action-modal__details .ant-collapse-content > .ant-collapse-content-box {
    color: var(--gray-8);
    padding-left: 0;
    padding-right: 0;
}

.completed-action-modal__details.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: inline-block;
    padding-left: 0;
}

.completed-action-modal__alert {
    width: 100%;
}

.completed-action-modal__alert.ant-alert {
    align-items: flex-start;
    margin: 1em 0;
}

.completed-action-modal__alert .anticon {
    margin-top: 0.3em;
}

.completed-action-modal__radio .ant-form-item-label > label {
    height: auto;
}
