.customer-pets {
    margin-left: 275px;
    height: calc(100vh - 56px);
    overflow: auto;
    background-color: #fff;
    padding: var(--spacing-lg) var(--spacing-md);
}

.customer-pets__header {
    margin-bottom: var(--spacing-lg);
    display: flex;
    justify-content: space-between;
}

.customer-pets__header h1 {
    font-size: 20px;
}

.customer-pets__filters {
    margin-bottom: var(--spacing-xl);
    display: inline-flex;
    column-gap: var(--spacing-sm);
}

.customer-pets__pet-filter {
    width: fit-content;
    min-width: 257px;
    max-width: 500px;
}

.customer-pets__balance-filter {
    width: fit-content;
    min-width: 230px;
    max-width: 500px;
}

.customer-pets__pet-name {
    display: inline-flex !important;
    align-items: center;
}

.customer-pets__pet-page-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: var(--spacing-md);
}

@media only screen and (max-width: 600px) {
    .customer-pets {
        margin: 0;
        height: auto;
    }

    .customer-pets__filters {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .customer-pets__filters .ant-select {
        width: 100%;
        min-width: unset;
    }
}
