.rabies-vaccination-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.rabies-vaccination__label {
    display: inline-flex;
    width: 19%;
    align-items: center;
}

.rabies-vaccination__header-label-container {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}

.rabies-vaccination__header-label-container :first-child {
    margin-right: var(--spacing-xs);
}

.rabies-vaccination__header-label-container :not(:last-child) {
    margin-bottom: var(--spacing-xs);
}

.rabies-vaccination-container .ant-form-item {
    padding: 0;
}

.ant-divider-horizontal.rabies-vaccination-container__divider::before {
    width: 0;
}

.ant-divider-horizontal.ant-divider-with-text.rabies-vaccination-container__divider {
    margin-top: var(--spacing-lg);
}

.rabies-vaccination-container__divider .ant-divider-inner-text {
    padding-left: 0;
}

.rabies-vaccination-container .white-space-wrap label {
    white-space: normal;
    height: auto;
}

.rabies-vaccination-container .ant-picker,
.rabies-vaccination-container .ant-input,
.rabies-vaccination-container .ant-input-group-compact,
.rabies-vaccination-container .ant-radio-group,
.rabies-vaccination-container .ant-select {
    width: 50% !important;
    margin: 0 !important;
}

.vaccination-data-container .ant-form-item,
.veterinarian-data-container .ant-form-item {
    padding-bottom: var(--spacing-sm);
}

.rabies-vaccination__approved .vaccination-data-container .ant-form-item,
.rabies-vaccination__approved .veterinarian-data-container .ant-form-item {
    padding-bottom: 0;
}

.vaccination-data-container .ant-input-group-compact::before,
.vaccination-data-container .ant-input-group-compact::after {
    display: none;
}

.vaccination-data-container .ant-input-group-compact {
    display: flex;
    gap: 10px;
}

.rabies-vaccination__buttons {
    width: 816px;
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-sm);
    padding: var(--spacing-lg);
    border: 1px solid var(--gray-5);
    background-color: #fff;
    margin: 2%;
    margin-top: var(--spacing-lg);
}

.approved-form__veterinarian-data-container {
    display: flex;
    gap: var(--spacing-lg);
    margin-top: 32px;
}

.approved-form__veterinarian-data-container span {
    flex-grow: 1;
}

.approved-form__veterinarian-data-container span div:nth-child(1) {
    padding-bottom: var(--spacing-sm);
}

.approved-form__veterinarian-data-container span div:nth-child(2) {
    border-top: 1px solid var(--gray-7);
    padding-top: var(--spacing-sm);
}
