.ant-tag.patient-alert-tag {
    position: relative;
    padding-left: 24px;
    display: inline-flex;
    margin: 0 0 8px;
}

.ant-tag.patient-alert-tag > span.ellipsis {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-tag.patient-alert-tag.horizontal {
    margin: 4px 8px 4px 0;
}

.ant-tag.patient-alert-tag.no-text {
    height: 22px;
    width: 40px;
    padding-left: 0;
    padding-right: 0;
}

.ant-tag.patient-alert-tag.no-category {
    padding-left: 8px;
}

.patient-alert-tag.information {
    background-color: var(--geek-blue-1);
    color: var(--geek-blue-6);
    border-color: var(--geek-blue-3);
}

.patient-alert-tag.use-caution-staff {
    background-color: var(--gold-1);
    color: #874a00;
    border-color: var(--gold-3);
}

.patient-alert-tag.medical-risk-patient {
    background-color: rgba(231, 32, 46, 0.10);
    color: var(--veg-red);
    border-color: var(--veg-red);
}

.patient-alert-tag .option-icon {
    margin-right: 4px;
    font-size: 16px;
    position: absolute;
    left: 4px;
    top: 2px;
}

.ant-tag.patient-alert-tag.no-text .option-icon {
    margin-right: 0;
    left: 12px;
}
