.message-content {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.message-sender,
.message-time {
	align-self: flex-end;
	font-size: 12px;
	color: var(--gray-8);
}

.message-content__text {
	border: 1px solid var(--gray-5);
	border-radius: 20px;
	padding: 12px 16px;
}
