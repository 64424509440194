.doctor-production-report-container {
	padding-left: 24px;
}

.doctor-production-report-container h1 {
	font-size: 30px;
	margin-bottom: 24px;
	margin-top: 40px;
}

.doctor-production-report-container > .sub-header {
	margin-bottom: 16px;
}
