.pendo-badge{
    width: 55px;
    cursor: pointer;
}

.pendo-badge-collapse {
    width: 45px;
}

@media only screen and (max-width: 1200px) {
	.pendo-badge-collapse  {
		width: 35px;
	}
}

.pendo-badge-absolute {
    position: fixed;
    inset: auto auto 50px 70px;
}
