.attach-rdvm-container {
    display: flex;
    flex-direction: column;
}

.attach-rdvm-container > *:not(:first-child) {
    margin-top: var(--spacing-md);
}

.self-end {
    align-self: end;
}

.ant-select-item .rdvm-options-divider {
    margin: 10px -12px;
    width: auto;
}

.rdvm-options-title,
.rdvm-options-state {
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.rdvm-options-title {
    color: var(--gray-9);
    font-weight: var(--font-semi-bold);
}
