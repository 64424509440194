.CPRStatusSubtitle-container {
    text-transform: uppercase;
    padding: 0 var(--spacing-md);
}

.CPRStatusSubtitle__status {
    margin-bottom: var(--spacing-xs);
    position: relative;
}

.CPRStatusSubtitle__status,
.CPRStatusSubtitle__spike-3 {
    display: flex;
    align-items: center;
    min-height: 1.5rem;
}

.CPRStatusSubtitle__status > span {
    margin-left: var(--spacing-lg);
}

.CPRStatusSubtitle__status::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 0.5rem;
    left: 6px;
}

.CPRStatusSubtitle__status--stable::before {
    background-color: var(--gray-8);
}

.CPRStatusSubtitle__status--urgent::before {
    background-color: var(--veg-secondary-gold);
}

.CPRStatusSubtitle__status--critical::before {
    background-color: var(--veg-secondary-red);
}

.CPRStatusSubtitle__spike-3 .anticon {
    margin-right: 0.625rem;
}

@media only screen and (max-width: 1320px) {
    .CPRStatusSubtitle-container {
        display: flex;
        margin-top: var(--spacing-md);
        gap: var(--spacing-sm);
        grid-row: 3;
        grid-column: 1/3;
        padding: 0;
    }

    .CPRStatusSubtitle__status {
        margin-bottom: 0;
    }
}
