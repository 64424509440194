.approved-consent-form {
    width: 816px;
    margin: auto;
}

.approved-consent-form .document {
    margin: auto;
}

.approved-consent-form__print-button {
    display: flex;
    border-bottom: 1px solid var(--gray-5);
    padding: var(--spacing-sm) 0;
    margin-bottom: var(--spacing-sm);
}

.approved-consent-form__print-button button {
    width: fit-content;
    margin-left: auto;
}

.approved-consent-form__approved-at {
    border: 1px solid var(--gray-5);
    padding: var(--spacing-lg);
    text-transform: uppercase;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--font-semi-bold);
    margin-top: var(--spacing-lg);
}

@media screen and (max-width: 800px) and (min-width: 600px) {
    .approved-consent-form {
        width: 650px;
    }
}

@media screen and (max-width: 600px) {
    .approved-consent-form {
        width: 100%;
    }

    .print-estimate-page__print-button-container {
        padding: var(--spacing-sm);
    }
}
