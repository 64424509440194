.checkout-drawer .checkout-item {
    padding: var(--spacing-sm) 0;
    line-height: 20px;
}

.checkout-drawer .checkout-item button {
    display: flex;
    height: 20px;
    align-items: center;
    height: auto;
    margin: 0;
    padding: 0;
    min-height: 0;
}

.checkout-drawer .checkout-item svg {
    display: block;
}

.referral-source-modal .ant-form-item-label {
    display: block;
    width: 100%;
    text-align: left;
    white-space: initial;
}

.referral-source-modal .ant-form-item-label > label {
    height: auto;
    min-height: 32px;
}

.checkout-drawer.ant-drawer .ant-drawer-mask {
    animation: none !important;
}

.checkout-drawer .required-message {
    width: 100%;
    padding: 0 0 var(--spacing-md);
}

.veg-drawer.checkout-drawer .ant-alert-info {
    margin-top: 0;
    margin-bottom: 16px;
}

.veg-drawer.referral-source-drawer .ant-space {
    padding: 0 0 var(--spacing-md) 0;
}

.veg-drawer.referral-source-drawer .primary-contact-message {
    padding-top: var(--spacing-md);
}

.veg-drawer.referral-source-drawer .additional-info {
    white-space: pre-wrap;
}

.veg-drawer.referral-source-drawer .additional-info-textarea {
    min-height: 8em;
}

.checkout-drawer.referral-source-drawer .referred-by-primary-vet {
    margin-top: 10px;
}

.veg-drawer.referral-source-drawer .ant-radio-group {
    margin-top: 10px;
}

.customer-birthday-form .ant-col-24.ant-form-item-label {
    padding: 0;
}
