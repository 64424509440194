.rct-sidebar-row.rct-sidebar-row-even,
.rct-sidebar-row.rct-sidebar-row-odd {
	background-color: #fff !important;
}

.main-ts-timeline .rct-item {
	border-color: var(--gray-7) !important;
}

.main-ts-timeline .rct-item-content {
	text-shadow: none !important;
}

.main-ts-title {
	font-size: 20px;
	margin-bottom: 24px;
}

.main-ts-row-header {
	line-height: normal;
	padding: 8px 16px;
}

.main-ts-row-header__first-row {
	display: flex;
	align-items: center;
	margin-bottom: 8px;
}

.main-ts-row-header__patient-name {
	color: var(--gray-9);
	font-weight: var(--font-medium);
	font-size: 16px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.main-ts-row-header__functions {
	display: flex;
    align-items: center;
	margin-left: auto;
}

.main-ts-row-header__functions .anticon {
	margin-right: 10px;
}

.main-ts-row-header__functions .anticon svg {
	width: 16px;
	height: 16px;
}

.ant-btn.main-ts-row-header__view-visit-button {
	padding: 0;
	height: 24px;
	width: 48px;
}

.main-ts-row-header__patient-signalment {
	color: var(--gray-8);
}

.main-ts-row-header__reason-for-visit {
	color: var(--gray-8);
	max-width: 20em;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-top: 2px;
}

.main-ts-row-owner {
	font-size: 12px;
	font-weight: bold;
	margin-bottom: 0px;
	min-height: 14px;  /* spaces signalment down in case last name is empty, where normally text takes 12px for font-size plus 1px margin */
}

.main-ts-row-signalment {
	font-size: 10px;
	margin-bottom: 0px;
}

.main-ts-actions-due {
	font-size: 14px;
	font-weight: var(--font-medium);
}

.main-ts-cell {
	background-color: #838DDC;
}

.main-ts-actions-complete {
	font-size: 18px;
	font-weight: bold;
	background-color: #34b233;
}

.main-ts-popover {
	min-width: 400px;
}

.main-ts-action-claimed {
	color: rgba(0, 0, 0, 0.5);
}

.main-ts-cell-overdue {
	background-color: var(--veg-red);
}

.main-ts-action-claimed .main-ts-action-name {
	font-style: italic;
}

.main-ts-current-cell {
	background-color: rgba(256, 256, 256, 0.25);
}

.main-ts-action-completed {
	color: rgba(0, 0, 0, 0.5);
	font-style: italic;
}

.main-ts-action-inprogress {
	color: rgba(0, 0, 0, 0.5);
	font-style: italic;
}

.main-ts-action-skipped {
	color: rgba(0, 0, 0, 0.5);
	font-style: italic;
}

/* React calendar timeline next release will include className for Timeline component */
.main-ts-timeline .react-calendar-timeline .rct-scroll {
	overflow-x: hidden !important;
}

.react-calendar-timeline .main-ts-primary-header > div:first-child {
	background-color: var(--gray-2);
}

.react-calendar-timeline .main-ts-primary-header .rct-dateHeader-primary {
	position: relative !important;
	width: auto !important;
	left: auto !important;
}

.main-ts-primary-header__inpatient {
	background-color: var(--veg-secondary-teal);
}

.main-ts-primary-header__outpatient {
	background-color: var(--veg-secondary-blue);
}
