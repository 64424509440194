.web-checkout-container {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100vw;
  height: 100vh;
}

.web-checkout-container-inner {
  display:flex;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 1.71428571em;
  width: 94vw;
  max-width: 500px;
  background: #fff;
}

.web-checkout-container .payment-info {
  margin: 0;
  padding: 0;
  font-size: 12.5px;
  color: #8C8C8C;
}

.web-checkout-container .donation {
  background: #F7F7F7;
  margin-top: 1em;
  padding: 1em;
  border-radius: 2px;
}

.web-checkout-container .donation-info {
  margin-top: 1em;
}

.web-checkout-container .ant-form-item-explain {
  position: absolute;
  left: 9.4em;
  min-width: 8em;
  top: 0.3em;
}

@media (min-width: 576px) {
  .web-checkout-container .payment-info {
    font-size: 14px;
  }

  .web-checkout-container .donation-info {
    margin-top: 0em;
  }

  .web-checkout-container .ant-form-item-explain {
    left: auto;
    top: 2.4em;
  }
}
