.whiteboard-container {
    background-color: #fff;
    min-height: 100vh;
}

.whiteboard__header {
    display: flex;
    align-items: center;
    padding: var(--spacing-md);
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 2;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.whiteboard__VEG-logo {
    padding-right: var(--spacing-md);
    border-right: 1px solid var(--gray-4);
}

.whiteboard-container h1 {
    color: #000;
    text-align: center;
    line-height: normal;
    margin-left: 8.125rem;
    margin-bottom: 0;
}

.whiteboard__title {
    font-family: 'Zona Pro';
    font-size: 2.25rem;
    text-transform: uppercase;
}

.whiteboard__sub-title {
    font-family: 'Zona Pro Bold';
    font-size: 1.5rem;
}

.whiteboard__indicators-container {
    display: flex;
    gap: var(--spacing-md);
    margin-left: auto;
}

.whiteboard__board {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: var(--spacing-sm);
    padding: var(--spacing-sm);
}

.whiteboard__board-first-column > *:not(:last-child) {
    margin-bottom: var(--spacing-sm);
}

@media only screen and (max-width: 1320px) {
    .whiteboard__header {
        display: grid;
        grid-template-columns: 41px auto;
        gap: var(--spacing-md) 0;
    }

    .whiteboard__VEG-logo {
        grid-row: 1;
    }

    .whiteboard__board {
        grid-template-columns: minmax(100%, 1fr);
    }

    .whiteboard-container h1 {
        margin: 0;
        grid-row: 1;
    }

    .whiteboard__title {
        font-size: 1.25rem;
    }

    .whiteboard__sub-title {
        font-size: 1rem;
    }

    .whiteboard__indicators-container {
        gap: var(--spacing-sm);
        grid-column: 1/3;
        grid-row: 2;
        width: 100%;
    }
}
