.patient-documents-tab {
	padding: var(--spacing-lg);
	padding-top: var(--spacing-md);
}

.patient-documents-tab h1 {
	font-size: 20px;
	margin-bottom: var(--spacing-lg);
}

.add-patient-document-button-container {
	margin-bottom: var(--spacing-sm);
}

.add-patient-document-button-container button {
	display: flex;
	align-items: center;
}

.add-patient-document-button-container button .anticon {
	height: 14px;
}

.ant-btn.file-function-button {
	border: none;
	background-color: transparent;
}

.file-function-button:not(:last-child) {
	margin-right: 10px;
}

.file-function-button svg {
	color: var(--gray-7);
}
