.ant-table-wrapper.dashboard-minimized .ant-table-content {
    display: none;
}

.call-details-form {
    margin-left: 8px;
}

.call-details-form .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.call-details-form .ant-form-item-label>label::after {
    display: none;
}

.call-details-form .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: block;
    content: '*';
    color: #ff4d4f;
    font-family: SimSun, sans-serif;
}

.call-details-form .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: inherit;
}

.contact-button {
    padding-left: 0px !important;
    padding-right: 0px !important;
    display: block !important;
    color: var(--veg-secondary-blue) !important;
}

.contact-button svg {
    width: 18px;
    height: 18px;
}

.anticon.patient-table-row-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: var(--gray-9);
    border: 1px solid var(--gray-5);
    width: 32px;
    height: 32px;
    border-radius: 2px;
}

.spike-3-pop-confirm>.ant-btn,
.spike-3-pop-confirm>.ant-btn:hover,
.spike-3-pop-confirm>.ant-btn:active,
.spike-3-pop-confirm>.ant-btn:focus,
.ant-btn.spike-3-pop-confirm,
.ant-btn.spike-3-pop-confirm:hover,
.ant-btn.spike-3-pop-confirm:active,
.ant-btn.spike-3-pop-confirm:focus {
    border-color: var(--gray-7) !important;
    background-color: var(--veg-tint-plum) !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

td.ant-table-cell:has(> span.triage) {
    border-left-width: 4px;
    border-left-style: solid;
}

td.ant-table-cell:has(> span.triage.triage-critical) {
    border-left-color: var(--veg-secondary-red);
}

td.ant-table-cell:has(> span.triage.triage-urgent) {
    border-left-color: var(--veg-secondary-gold);
}

td.ant-table-cell:has(> span.triage.triage-stable) {
    border-left-color: var(--gray-9);
}
