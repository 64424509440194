.ant-form-item {
    margin-bottom: 0px;
    padding-top: 8px;
}

.ant-form-horizontal .ant-form-item-control {
	min-width: initial;
}

.ant-radio-button-wrapper:first-child {
    border-radius: 4px 0 0 4px;
}

.ant-radio-button-wrapper:last-child {
    border-radius: 0 0 0 0;
}

.ant-radio-button-wrapper {
    color: #585757;
    border-color: #dbdbdb;
}

.ant-radio-button-checked {
    color: #585757;
    border-color: #dbdbdb;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #585757;
    border-color: #dbdbdb;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #585757;
    border-color: #dbdbdb;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    color: #585757;
    border-color: #dbdbdb;
    border-right-color: #dbdbdb;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    color: #585757;
    border-color: #dbdbdb;
    border-right-color: #dbdbdb;
}

.ant-radio-button-wrapper:not(:first-child)::before {
    color: #585757;
    border-color: #dbdbdb;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: #dbdbdb;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #dbdbdb;
}

.rdvm-select {
    text-transform: capitalize;
}

.modify-problem-name-header {
    text-transform: capitalize;
}

.diff-select-tag {
    text-transform: capitalize;
    padding: 4px 6px;
    background: rgb(224, 224, 224);
    border-radius: 2px;
    margin: 0 4px;
}

.diff-select-tag.suspected {
    background: rgb(120, 120, 250);
    color: white;
}

.diff-select-tag.confirmed {
    background: green;
    color: white;
}

.diff-select-tag.ruled-out {
    background: red;
    color: white;
}

.phys-exam-timestamp {
    font-weight: bold;
    font-size: 14px;
}

.phys-exam-byline {
    margin-bottom: 10px;
}

.phys-exam-note {
    margin-bottom: 2px;
}
.markdown-enabled-caption {
    font-weight: bold;
    font-size: 12px;
}

.edit-complaint-diff-list {
    text-transform: capitalize;
}

.customer-info-form.ant-divider-horizontal {
    margin: 12px 0;
}

.detailed-multi-complaint-table {
    text-transform: capitalize;
}

.package-instruction-row-excluded.parent,
.package-instruction-row-excluded label {
    color: rgba(0, 0, 0, 0.4);
    background: rgba(0, 0, 0, 0.03);
}

.package-instruction-row-excluded.parent:hover td {
    /* TODO there is prob better way of overriding ANTD than important... */
    background: rgba(0, 0, 0, 0.03) !important;
}

.cri-form-divider {
    margin: 12px 0;
}

.non-medical-reason-info {
    font-size: 14px;
    min-height: 24px;
    line-height: 1.5715;
    width: 100%;
}

.alert-with-icon-aligned-to-top {
    align-items: flex-start;
    
}

.alert-with-icon-aligned-to-top .anticon {
    margin-top: 0.2em;
}

.ant-btn-compact-item.ant-btn.ant-btn-compact-first-item {
    margin-left: auto;
}
