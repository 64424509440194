.loginWrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.loginWrapper :global button {
  width: 100%;
  margin-top: 1rem;
}
.form:global(.ant-form) {
  padding-bottom: 4rem;
}
.userAvatar {
  width: 120px;
  height: 120px;
  background-color: #d6d6d6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem auto;
}
.userAvatar :global svg {
  color: #fff;
  font-size: 41px;
}
