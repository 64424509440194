.veg-drawer.earnings-drawer .ant-drawer-body {
    padding-top: 24px;
}

.hidden-picker {
    visibility: hidden;
    height: 0;
    width: 0;
    border: 0;
    padding: 0;
}

.left-arrow-btn,
.right-arrow-btn {
    width: 48px;
}

.left-arrow-btn {
    border-radius: 2px 0 0 2px;
}

.right-arrow-btn {
    border-radius: 0 2px 2px 0;
}

.month-btn {
    border-radius: 0;
    width: 56px;
}

.metrics-container {
    margin-left: auto;
}

.earnings-metric {
    display: inline-block;
    padding: 0 15px 0 15px;
}

.earnings-metric-value {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    color: var(--gray-9);

    display: block;
    text-align: center;
}

.earnings-metric-indicator {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: var(--gray-8);

    display: block;
    text-align: center;
}

.earnings-metric-revenue .earnings-metric-value {
    color: var(--veg-green);
}

.earnings-metric-adjusted .earnings-metric-value {
    color: var(--veg-secondary-red);
}

.earnings-top {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .earnings-top {
        flex-direction: column;
        align-content: space-between;
    }

    .metrics-container {
        margin-left: 0;
        margin-top: 12px;
        display: flex;
        flex-wrap: wrap;
    }

    .earnings-metric {
        flex-basis: 50%;
        padding: 15px;
    }
}
