.visitsTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    padding-left: 24px;
    padding-top: 40px;
}

.visitsTable {
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 72px;
}

.visitsTable :global .ant-table-column-sorters {
    display: flex;
    justify-content: space-between;
}

.visitsTable_currency {
    text-align: right;
}

.visitsTable_status {
    min-width: 200px;
}

.rowCount {
    position: absolute;
    right: 0;
    top: 36px;
    color: var(--gray-7);
    padding: 0 24px;
    text-align: right;
}
