.__fade-transition-mixin {
  transition: all linear 250ms;
}
.readNotif {
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
}
.inboxNotificationModal :global(.ant-modal-body) {
  padding: 4px;
}
.markdownContainer :global(p),
.markdownPreview :global(p) {
  margin-bottom: 0;
}
.markdownFormWrapper {
  padding: 0 12px;
}
.searchInput {
  width: 300px;
}
.tableWrapper {
  padding: 12px;
}
