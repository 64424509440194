.visit-treatment-sheet .react-calendar-timeline {
	max-height: calc(100vh - 276px);
	overflow-y: auto;
	overflow-x: hidden;
}

/* Overrides for the "zebra" table striping that I can't figure out how to get rid of otherwise */
.visit-treatment-sheet .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd,
.visit-treatment-sheet .react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
	position: relative;
	border-color: var(--gray-5) !important;
	background-color: transparent;
}

.visit-treatment-sheet .react-calendar-timeline .rct-header-root {
	background: var(--gray-9);
	position: sticky;
	top: 0;
	z-index: 100;
}

.visit-treatment-sheet .react-calendar-timeline .rct-header-root:first-child {
	background-color: var(--gray-2);
}

.show-discontinued-container {
	background-color: var(--gray-2);
	color: var(--gray-9);
	font-weight: var(--font-medium);
	padding-left: 8px;
	padding-right: 12px;
	display: flex;
}

.show-discontinued-container__button {
	margin-left: auto;
	margin-right: 5px;
}

.sidebar-group {
	line-height: 24px;
	height: 100%;
	background: #fff;
}

.ts-context-menu-title {
	text-transform: capitalize;
}

.ts-cri-review-popover {
	/* Matches compose box width, which is the other spot we display a `CRI review` */
	width: 484px;
}
.ts-fluid-review-popover {
	/* Way less info than CRI review, so, narrower popover */
	width: 350px;
}

.ts-group-wrapper {
	height: 100%;
}

.ts-group-wrapper__discontinued .sidebar-group {
	background-color: rgba(217, 217, 217, .5);
	color: var(--gray-6) !important;
}

.ts-group-wrapper__discontinued .treatment-sheet-side-column {
	color: rgba(38, 38, 38, .7) !important;
}

.ts-group-wrapper__discontinued .diagnostic-data__last-value {
	color: rgba(89, 89, 89, .7) !important;
}

.ts-group-wrapper__discontinued .discontinued-icon {
	color: var(--veg-red);
	margin-left: var(--spacing-sm);
}

.treatment-sheet-side-column__title-wrapper .no-charge-icon svg {
	width: 15px;
	height: 15px;
}

.ts-group-wrapper__discontinued .order-context-menu {
	margin-left: var(--spacing-xs);
	background-color: transparent;
}

.ts-group-wrapper__discontinued .order-context-menu:hover {
	background-color: transparent;
}

.ts-group-wrapper .no-charge-icon {
	margin-left: var(--spacing-sm);
}

.root-group {
	display: flex;
	align-items: center;
	line-height: 24px;
	background: #F0F5F7;
	color: #000;
	height: 100%;
	padding-left: 8px;
	font-weight: var(--font-semi-bold);
}

.visit-treatment-sheet .react-calendar-timeline .rct-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 2px;
	border-bottom: .5px solid white !important;
	border-left: .5px solid white !important;
}

.react-calendar-timeline .rct-item .reschedule-action-button {
	height: var(--spacing-lg) !important;
	width: var(--spacing-lg);
	padding: 0 !important;
	background: transparent !important;
	border: none !important;
	position: absolute;
	top: 0px;
	right: 2px;
	z-index: -1;
}

.react-calendar-timeline .rct-item .reschedule-action-button .anticon {
	color: #fff;
}

.react-calendar-timeline .rct-item .rct-item-content {
	font-weight: bold;
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
	color: #fff;
	padding: 0;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

.react-calendar-timeline .rct-item .rct-item-content.additive-marker-content {
	display: flex;
	align-items: center;
}

.react-calendar-timeline .rct-calendar-header,
.react-calendar-timeline .rct-dateHeader {
	border: none;
}

.visit-treatment-sheet .react-calendar-timeline .rct-calendar-header {
	background: rgba(38, 38, 38, 0.85);
}

.react-calendar-timeline .rct-sidebar {
	border-right: none;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
	padding: 0;
	border-bottom: 1px solid var(--gray-5);
}

.visit-treatment-sheet .react-calendar-timeline .rct-item .note-icon {
	font-size: var(--spacing-md);
	position: absolute;
	left: 2px;
	bottom: 4px;
	z-index: -1;
}

.rct-scroll > div > div:first-child {
	background-color: var(--veg-secondary-teal) !important;
	width: 6px !important;
	z-index: 41;
}

.ts-before-visit-cell,
.ts-future-cell,
.ts-past-cell,
.ts-current-cell {
	border-color: var(--gray-7) !important;
}

.row-root,
.row-item {
	border-color: var(--gray-5) !important;
}

.row-root {
	background: #F0F5F7 !important;
	z-index: 42 !important;
}

.ts-discontinued-group {
	background-color: rgba(217, 217, 217, .5) !important;
}

.rct-dateHeader.rct-dateHeader-primary > span {
	display: block;
	position: absolute;
	padding: 0 0.8em;
	white-space: nowrap;
}
/* button groups */
.btn-group-first {
	margin-right: -1px;
}

.btn-group-mid {
	border-radius: 0px !important;
}

.btn-group-last {
	margin-left: -1px;
}

.opened-ts-item-group {
	display: flex;
	align-items: center;
}

.opened-ts-item-group .anticon {
	margin-right: 8px;
	font-size: 12px;
}

.idexx-recently-completed tbody {
	vertical-align: baseline;
}

.idexx-remove-button.ant-btn-icon-only {
	position: absolute;
	right: 0;
	top: 0;
}

.treatment-sheet-marker-modal .ant-modal-header {
	padding: 16px 24px;
	padding-right: 54px;
}
