.ledger-box,
.ledger-box-bottom {
    border: 1.5px solid var(--gray-5);
    padding: 4px var(--spacing-sm);
    background-color: #ffffff;
    justify-content: space-between;
}

.ledger-box {
    border-bottom: none;
}

.ledger {
    text-align: right;
    display: flex;
    width: 375px;
    margin-top: 20px;
}

.discount-item {
    text-transform: capitalize;
}

.delete-invoice-cap-button {
    border: none !important;
    color: var(--gray-8) !important;
}

.divider-item {
    pointer-events: none;
    padding: 0 0!important;
}

.ant-select-dropdown .ant-select-item-option.divider-item .ant-select-item-option-content > .ant-divider-horizontal {
    margin: 2px !important;
 }

.ant-select-dropdown .ant-select-item-option.divider-item
{
    min-height: 1px !important;
}
