.visible-spinner .ant-input-number-handler-wrap {
  opacity: 1;
}

.patient-alerts-collapse {
  width: 100%;
}

.patient-alerts-collapse .ant-collapse-content {
  height: auto !important;
}

.patient-alerts-collapse .ant-collapse-content.ant-collapse-content-inactive {
  height: 0 !important;
}

.patient-alerts-collapse .ant-collapse-item .ant-collapse-content-box {
  padding: 0;
}

.ant-collapse > .patient-alerts-collapse.ant-collapse-item > .ant-collapse-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 12px 0;
  margin: 0;
}

.ant-collapse > .patient-alerts-collapse.ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  left: 0;
  top: 8px;
}

.patient-alerts-collapse .ant-collapse-header .ant-divider-horizontal {
  margin: 0;
  width: 100%;
  clear: initial;
  min-width: 0;
  padding-right: 8px;
}

.patient-alerts-collapse .ant-form-item {
  padding-top: 0;
}

.patient-alerts-collapse .ant-divider-inner-text {
  padding-left: 8px;
}

.patient-alerts-collapse .ant-divider-with-text-left::before {
  border-top: 0px !important;
}

.patient-alerts-collapse .patient-alert-info {
  font-size: 12px;
}

.patient-alerts-collapse .patient-alert-info h4 {
  font-weight: var(--font-bold);
}

.patient-alert-category-option .ant-select-item-option-content {
  display: flex;
  align-items: center;
}

 .patient-alert-category-option .ant-select-item-option-content .option-icon,
 .ant-select-selection-item .option-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  
  margin-right: 8px;
  font-size: 18px;
}

.patient-alert-category-option .ant-select-item-option-content .no-category-icon,
.ant-select-selection-item .no-category-icon {
  border: 1px solid var(--gray-6);
  border-radius: 50%;
}

.patient-alerts-collapse .ant-select-selection-item {
  display: flex;
  align-items: center;
}

.patient-alerts-collapse .ant-select-selection-placeholder {
  margin-left: 26px !important;
}

.patient-alerts-collapse .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  padding-left: 26px;
}

.delete-deceased-button.ant-btn-icon-only {
  padding: 0 4px;
  width: auto;
  height: auto;
  border: 0;
}
