.physical-exam-card-title {
    display: flex;
    align-items: center;
}

.physical-exam-details {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    font-size: 0.9rem;
    font-weight: var(--font-regular);
}

.physical-exam-details .ant-typography {
    font-size: 16px;
    font-weight: var(--font-medium);
    color: var(--gray-9);
}

.physical-exam-details__separator {
    color: var(--gray-4);
}

.physical-exam-details__time {
    color: var(--gray-8);
}

.physical-exam-content {
    padding: var(--spacing-md);
}

.physical_exam_card {
    width: min(100%, 400px);
    border: 1px solid #000;
    border-radius: 2px;
    padding: 0 var(--spacing-sm);
}

.physical_exam_card:not(:last-child) {
    margin-bottom: var(--spacing-sm);
}

.physical_exam_card__title {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.physical_exam_card__title .anticon {
    margin-left: auto;
    color: var(--gray-8);
}

.physical_exam_card__normal {
    background-color: var(--green-1);
    color: var(--green-6);
    border-color: var(--green-3);
}

.physical_exam_card__abnormal {
    background-color: var(--red-1);
    color: var(--red-6);
    border-color: var(--red-3);
}

.physical_exam_card__not-observed {
    background-color: var(--gray-3);
    color: var(--gray-8);
    border-color: var(--gray-6);
}

.physical_exam_data {
    color: var(--gray-9);
}

.physical_exam_data__name {
    font-weight: 500;
    text-transform: capitalize;
}

.physical_exam_data__note {
    margin-left: var(--spacing-md);
}
