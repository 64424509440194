.written-prescription-container > *:not(:last-child) {
    margin-bottom: var(--spacing-md);
}

.written-prescription-container .ant-alert {
    width: fit-content;
    align-items: flex-start;
}

.written-prescription-container .ant-alert-content {
    text-transform: uppercase;
    font-weight: var(--font-medium);
}

.written-prescription-container .ant-alert .anticon {
    margin-top: 2px;
}

.written-prescription-form {
    padding-top: var(--spacing-md) !important;
}

.written-prescription-form .ant-alert {
    margin-top: var(--spacing-sm);
    margin-bottom: var(--spacing-lg);
    align-items: flex-start;
}

.written-prescription-form .ant-alert .anticon {
    margin-top: 2px;
}

.written-prescription-form .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-sm);
    margin-left: calc(var(--spacing-lg) * -1);
    width: calc(100% + (2 * var(--spacing-lg)));
    padding: 10px var(--spacing-lg) 0;
    margin-bottom: -14px;
}

.written-prescription-form .ant-modal-footer .ant-space-compact-block {
    width: auto;
}

.ant-dropdown-menu-item.written-prescription-container__danger-button {
    color: var(--red-5);
}

.written-prescription-container__voided .written-prescription-container__item {
    opacity: 0.5;
}

.written-prescription-container__medication-name {
    display: inline-block;
    font-weight: var(--font-semi-bold);
    margin-bottom: var(--spacing-sm);
}

.ant-tag.written-prescription-container__status-tag {
    margin-left: var(--spacing-md);
}

.ant-tag.written-prescription-container__status-tag--draft {
    color: var(--volcano-6);
    background-color: var(--volcano-1);
    border-color: var(--volcano-3);
}

.ant-tag.written-prescription-container__status-tag--voided {
    color: var(--antd-blue);
    background-color: var(--blue-1);
    border-color: var(--blue-3);
}

.written-prescription-container__table-sub-header {
    color: var(--gray-7);
    font-size: 12px;
}
