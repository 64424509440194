.bonus-action-form > div {
    margin: 0 !important;
    row-gap: 10px !important;
}

.bonus-action-form .note-section {
    margin-top: 0;
}

.bonus-action-modal--warning {
    margin-top: 16px;
    padding: 9px 16px;
    background-color: var(--gold-1);
    border: 1px solid transparent;
    border-color: var(--gold-3);
}

.bonus-action-modal--warning h2 {
    font-size: 14px;
    font-weight: var(--font-bold);
    text-transform: uppercase;
}

.bonus-action-modal--warning p {
    margin: 0;
}
