.treatment-sheet-side-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 4px 8px;
    color: var(--gray-9);
}

.treatment-sheet-side-column__title-wrapper {
    display: flex;
    align-items: center;
}

.treatment-sheet-side-column__title {
    max-width: calc(100% - 32px);
    overflow: hidden;
    font-weight: var(--font-bold);
    text-overflow: ellipsis;
    line-height: 22px;
}

.treatment-sheet-side-column__instruction-icon {
    font-size: 20px;
    margin-left: auto;
    margin-right: 4px;
}

.treatment-sheet-side-column .instructions-centered-container {
    display: flex;
    align-items: center;
}

.treatment-sheet-side-column__instruction-tooltip .ant-tooltip-inner {
    max-height: 300px;
    overflow-y: auto;
}

.treatment-sheet-side-column .instructions-centered-container .fluid-additives {
    display: inline-block;
    max-width: calc(100% - 100px);
    overflow: hidden;
    text-overflow: ellipsis;
}
