.App {
	margin: auto;
	-webkit-print-color-adjust: exact;
}

/* Todo see if we need to add this back into the system */
/* @media print {
	* {
		background-color: white;
	}
} */

