.veg-headline-1 {
    font-size: 30px;
    line-height: 38px;
}

.veg-headline-2 {
    font-size: 20px;
    line-height: 28px;
}

.veg-headline-3 {
    font-size: 18px;
    line-height: 26px;
}

.veg-headline-4,
.veg-subcaption-2 {
    font-size: 12px;
    line-height: 22px;
}

.veg-subtitle-1,
.veg-subtitle-2,
.veg-subtitle-3 {
    font-size: 16px;
    line-height: 24px;
}

.veg-body-1,
.veg-body-2,
.veg-body-3,
.veg-subcaption-1 {
    font-size: 14px;
    line-height: 22px;
}

.veg-headline-1,
.veg-headline-2,
.veg-headline-3,
.veg-subtitle-1,
.veg-subtitle-2,
.veg-body-2 {
    font-weight: 500;
}

.veg-headline-4,
.veg-subtitle-3,
.veg-body-1,
.veg-subcaption-1,
.veg-subcaption-2 {
    font-weight: 400;
}

.veg-body-3 {
    font-weight: 600;
}

.veg-headline-1,
.veg-headline-2,
.veg-headline-3,
.veg-headline-4,
.veg-subtitle-1,
.veg-subtitle-3,
.veg-body-1,
.veg-body-2,
.veg-body-3 {
    color: var(--gray-9);
}

.veg-subtitle-2,
.veg-subcaption-1,
.veg-subcaption-2 {
    color: var(--gray-8);
}

.veg-headline-4 {
    text-transform: uppercase;
}
