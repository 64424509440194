.billing-buttons .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: black;
    background: #fff;
    border-color: var(--veg-light-blue-2);
}
.billing-buttons .ant-radio-button-checked {
	color: black;
	border-color: var(--veg-light-blue-2);
	background-color: var(--veg-light-blue-2);
}
.billing-buttons .ant-radio-button-wrapper:not(:first-child)::before {
    color: black;
    border-color: var(--veg-light-blue-2);
}

.billing-buttons .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: black;
    border-color: var(--veg-light-blue-2);
}

.billing-buttons  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: black;
    border-color: var(--veg-light-blue-2);
}

.billing-buttons  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    color: black;
    border-color: var(--veg-light-blue-2);
	border-right-color: var(--veg-light-blue-2);
}

.billing-buttons  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    color: black;
    border-color: var(--veg-light-blue-2);
	border-right-color: var(--veg-light-blue-2);
}

.billing-buttons  .ant-radio-button-wrapper:not(:first-child)::before {
    color: black;
    border-color: var(--veg-light-blue-2);
}

.billing-buttons .ant-radio-button-wrapper:first-child {
	border-radius: 0;
}

.billing-buttons .ant-radio-button-wrapper {
    border-width: 1px;
    z-index: 1000;
}

.billing-buttons .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: var(--veg-light-blue-2);
}

.billing-buttons .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
	background-color: var(--veg-light-blue-2);
}

.vet-button {
    margin-right: 10px;
}

.billing-currency-display {
	font-size: 20px;
	font-weight: var(--font-semi-bold);
	margin-left: 0.2em;
}

.toggles-label {
	display: flex;
	align-items: center;
	padding: var(--spacing-xs) var(--spacing-md);
	background-color: #F0F5F7;
	border-radius: 2px 0px 0px 2px;
}

.radio-button-container {
	display: flex;
	flex-direction: row;
	gap: var(--spacing-sm);
}

.buttons-row-container {
	margin-bottom: var(--spacing-sm);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.review-and-finalize-button {
	margin-left: var(--spacing-sm);
	color: white !important;
	background-color: var(--veg-secondary-teal) !important;
}