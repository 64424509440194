.package-medication-dose__inputs > span {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 var(--spacing-sm);
}

.package-medication-container {
    position: relative;
}

.package-medication__dose-alert-icon {
    position: absolute;
    right: calc(var(--spacing-md) * -1);
    top: calc(50% - 4px);
}

.package-medication__dose-alert {
    grid-column: 3/7;
    grid-row: 2;
}

.package-medication__dose-alert > * {
    min-height: fit-content !important;
    margin: 0;
}

.package-medication__dose-alert .medication-dose-alert-section .ant-alert > .anticon svg {
    margin: 0;
}

.package-medication__dose-alert .ant-checkbox-wrapper {
    margin-top: var(--spacing-sm);
}

.package-medication__dose-alert--hide {
    display: none;
}

.package-medication__dose-alert + .edit-button-container {
    grid-row: 1/3;
    grid-column: 9;
}

.estimate-medication__empty-column {
    position: sticky;
    left: 0;
    background-color: #fff;
    grid-row: 2;
    grid-column: 1/2;
    z-index: 1;
}

.package-medication__empty-column::after {
    position: absolute;
    right: 0;
    top: 0;
    bottom: -1px;
    transform: translate(100%);
    width: 30px;
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
}

.show-left-shadow .package-medication__empty-column::after {
    box-shadow: inset 10px 0 8px -8px #00000026;
}

/* Estimate styles */

.estimate-table .package-medication__dose-alert {
    grid-column: 3/6;
}

.estimate-table .package-medication__dose-alert + .edit-button-container {
    grid-column: 6;
}

@media only screen and (max-width: 600px) {
    .package-medication__empty-column {
        display: none;
    }
}
