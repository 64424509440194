.patient-visits-and-notes-page {
    padding: var(--spacing-lg);
    padding-top: var(--spacing-md);
    height: calc(100vh - 114px);
    overflow: auto;
}

.patient-visits-and-notes-page h1,
.patient-visits-and-notes-page .ant-dropdown-trigger {
    margin-bottom: var(--spacing-lg);
}

.patient-visits-and-notes-page h1 {
    font-size: 20px;
}

.patient-visits-and-notes-page__button-container {
    margin-bottom: var(--spacing-sm);
}

.patient-visits-and-notes-page__button-container .ant-btn {
    display: inline-flex;
    align-items: center;
}

.patient-visits-and-notes-page__button-container .ant-btn .anticon {
    height: 14px;
}

.patient-visits-and-notes-page__button-container .ant-btn:not(:last-child) {
    margin-right: var(--spacing-sm);
}

/* Visits and note card */

.visits-and-note-card {
    border: 1px solid var(--gray-5);
    border-radius: 2px;
    background-color: #fff;
}

.visits-and-note-card:not(:last-child) {
    margin-bottom: var(--spacing-sm);
}

.visits-and-note-card__header {
    display: flex;
    align-items: center;
    padding: var(--spacing-sm) var(--spacing-md);
}

.visits-and-note-card__header .ant-avatar {
    margin-right: var(--spacing-sm);
}

.visits-and-note-card__header .ant-avatar .anticon {
    font-size: var(--spacing-md);
}

.visits-and-note-card__title {
    font-size: var(--spacing-md);
    font-weight: var(--font-medium);
}

.visits-and-note-card__separator {
    color: var(--gray-5);
}

.visits-and-note-card__function-button {
    margin-left: auto;
}

.visits-and-note-card__function-button .ant-btn {
    display: flex;
    align-items: center;
    padding: var(--spacing-sm);
}

.visits-and-note-card__function-button .anticon {
    height: 14px;
    transform: rotate(90deg);
}

.visits-and-note-card__content {
    border-top: 1px solid var(--gray-5);
    padding: var(--spacing-md);
    display: flex;
    gap: 8px;
}

.visits-and-note-card__content .ant-btn {
    border: none;
}

.visits-and-note-card__content p {
    margin: 0;
    overflow: hidden;
    color: var(--gray-8);
}

.visits-and-note-card__attachment {
    padding: var(--spacing-md);
    padding-top: 0;
}

.visits-and-note-card__attachment a {
    color: var(--gray-8);
}

.visits-and-note-card__reason-for-visit {
    display: inline-flex;
}

.visits-and-note-card__reason-for-visit > span {
    display: block;
    max-width: 25em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
}
