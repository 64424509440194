.order-context-menu {
	height: 25px !important;
	width: 25px;
	position: relative;
	border: 1px solid transparent !important;
}

.order-context-menu > span {
	top: 50%;
	right: 50%;
	transform: translate(50%, -50%);
	position: absolute;
}

.order-context-menu:hover {
	border: 1px solid #40a9ff !important;
}

.order-context-menu:active > span,
.order-context-menu:focus > span {
	position: absolute !important;
}
