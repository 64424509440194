.update-all-times-container {
    display: flex;
    align-items: center;
    gap: 0 var(--spacing-md);
    margin-left: auto;
}

@media only screen and (max-width: 600px) {
    .update-all-times-container {
        flex-direction: column;
        align-items: start;
        gap: var(--spacing-md) 0;
    }
}
