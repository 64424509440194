.ant-select.payment-button {
    margin-left: auto;
    height: 32px;
    justify-self: flex-end;
}

.ant-select.payment-button.ant-select-single .ant-select-selector {
    background: #1890ff;
    border-color: #1890ff;
    color: #fff;
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
}

.ant-select.payment-button.ant-select-single .ant-select-selector:hover,
.ant-select.payment-button.ant-select-single .ant-select-selector:focus,
.ant-select.payment-button.ant-select-single.ant-select-disabled .ant-select-selector {
    color: #fff;
    background: #40a9ff;
    border-color: #40a9ff;
}

.ant-select.payment-button.ant-select-single .ant-select-selection-item {
    color: #fff;
}

.ant-select.payment-button .ant-select-arrow {
    color: #fff;
    right: auto;
    left: 11px;
    top: 48%;
}

.payment-button .ant-select-dropdown {
    left: auto !important;
    right: 0 !important;
}

.ant-select.payment-button.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-right: 0;
    padding-left: 18px;
}

.payment-info-inline-button.ant-btn-link {
    display: inline;
    padding: 0;
    margin: 0;
    color: inherit;
    height: auto;
    float: right;
}

.payment-info-rows .ant-row {
    margin-bottom: 1.28571429em;
}

.payment-info-rows .ant-row:last-child {
    margin-bottom: 0;
}
