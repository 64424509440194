.container {
  padding: 1.5rem 2rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.header {
  font-size: 2rem;
  margin: -4rem 0 1rem;
  padding: 0;
}

.desc {
  font-size: 1.2rem;
  margin: 0 0 0;
  padding: 0;
}

.comingSoon {
  padding-bottom: 1em;
}

.button {
  width: 8em;
  margin: 0 0.5em 1em;
  display: block;
}

@media (min-width: 768px) {

  .button {
    margin-bottom: 0;
  }

}
