.payment-amount-input .ant-form-item-control {
    min-width: 0;
}

.payment-amount-input .ant-form-item-explain div[role=alert] {
    position: absolute;
    white-space: nowrap;
}

.terminal-reader-select label::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}
